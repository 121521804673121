body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast--default {
    background-color: white;
    color: rgb(153, 22, 230) !important;
    text-align: left;
}

.Toastify__progress-bar--default {
    background: rgb(153, 22, 230) linear-gradient(0deg, rgb(153, 22, 230), rgb(153, 22, 230)) !important;
}
