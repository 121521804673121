.navigation-logo{
    display: block;
    height: 100%;
    width: auto;
    margin-left: 15px;
}

.navigation-logo > img{
    box-sizing: border-box;
    height: 100%;
}

@media screen and (max-width: 600px){
    .navigation-logo{
        margin-left: 0;
        margin-top: 5px;
    }
    .navigation-logo > img{
        box-sizing: border-box;
        height: 70%;
    }
}