.navigation-bar {
    width: 100%;
    height: 60px;
    padding: 0 5px 5px 5px;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 19;

    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    /*choose*/
    background-color: rgba(255, 255, 255, 0.19);
    /*background-image: linear-gradient(45deg, rgba(141, 21, 22, 0.9), rgba(18, 25, 69, 0.9), rgba(255, 68, 70, 0.9));*/
    transition: background-color 200ms ease-in-out;
}

.navigation-bar.scrolled {
    background-color: white;
    box-shadow: 0 3px 5px rgb(61, 61, 61);
}

.navigation-bar > .logo-navlink{
    height: 100%;
    box-sizing: border-box;
}

.navigation-hamburger-menu {
    color: rgb(18, 25, 69);
}

.navigation-bar .navigation-hamburger-menu-container {
    display: none;
    align-self: flex-end;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 2;
    height: 100%;
}

.navigation-bar .navigation-menu {
    overflow: hidden;
    transform-origin: right;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    width: 100%;

    padding-left: 10px;
}

.navigation-menu .navigation-menu-item {
    display: block;
    padding: 5px 0;
    background-color: rgba(229, 64, 65, 0);
    margin: 0 10px 0 10px;
    position: relative;
    &:hover{
        &:after{
            width: 100%;
        }
    }
}
.navigation-menu .navigation-menu-item:after {
    content: '';
    width: 0;
    height: 4px;
    background-color: rebeccapurple;
    position: absolute;
    top: 98%;
    left: 0;
    transition: width 100ms ease-in-out;
}

.navigation-menu a {
    /*color: white;*/
    font-family: "Raleway Light", sans-serif;
    font-size: 21pt;
    color: rgb(70, 39, 120);
    text-decoration: none;
    border: 1px solid rgba(18, 25, 69, 0);
    transition: border-radius 200ms ease-in-out;
}

.navigation-menu a:hover {
    text-decoration: none;
}

.navigation-menu-item .dropdown-menu {
    border: 1px solid rgb(18, 25, 69);
}

@media screen and (max-width: 951px) {

    .navigation-bar .navigation-menu {
        transition: transform 400ms ease-in-out;
        width: 80vw;
        height: 100vh;
    }

    .navigation-bar .navigation-menu.closed {
        padding: 0;
        transform: translateX(100%);
    }

    .navigation-bar .navigation-menu.opened {
        position: absolute;
        right: 0;
        top: 0;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 20px 30px 20px 30px;

        background-color: rgba(102, 51, 153, 0.9);
        /*background-image: linear-gradient(40deg, rgba(255, 68, 70, 0.75), rgba(18, 25, 69, 0.75));*/
        z-index: 0;
        box-shadow: black -1px 0 15px 1px;
    }

    .navigation-menu.opened .navigation-menu-item {
        color: white;
        font-size: 1.12em;
        padding: 15px 10px 15px 10px;
    }

    .navigation-menu a:hover {

    }

    .navigation-menu a:active {

    }

    .navigation-menu.closed {
        width: 0;
    }

    .navigation-bar .navigation-hamburger-menu-container {
        display: flex;
    }
}
