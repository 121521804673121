footer {
    padding: 0 20px 0 10px;
    display: flex;
    width: 100%;
    min-height: 300px;
    box-sizing: border-box;
    background-image: linear-gradient(270deg, rgb(125, 62, 187), rgb(82, 50, 146));
    /*background-color: #0A0F25;*/
    color: white;
    font-size: 15pt;
    font-family: "Open Sans", sans-serif;
    z-index: 10;
}

.footerContainer {
    width: 100%;
    margin-top: 20px;
    padding: 0 5vw 0 5vw;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

@media screen and (max-height: 400px) {
    .footerContainer {
        margin-top: 40vh;
    }
}

.fullWidthFooterElement {
    width: 100%;
}

.footerElement {
    margin: 10px 5vw 10px 5vw;
}

.copyright {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    font-size: 12pt;
}

footer a, footer a:hover, footer a:visited {
    color: white !important;
    text-decoration: none;
    font-size: 12pt;
}
